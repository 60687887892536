/* API Base and EndPoints */

const baseUrl = process.env.REACT_APP_BASEURL

/* App Version API's */
const appVersions = {
  get : {
    method: 'GET',
    baseEndPoint: '/app',
    endPoint: '/versions'
  }
}

const users = {
  createUser: {
    method: 'POST',
    baseEndPoint: '/app',
    endPoint: '/user'
  },
  getAllUsers: {
    method: 'GET',
    baseEndPoint: '/app',
    endPoint: '/user/all'
  },
  patchUser: {
    method: 'PATCH',
    baseEndPoint: '/app',
    endPoint: '/user'
  },
  deleteUser: {
    method: 'DELETE',
    baseEndPoint: '/app',
    endPoint: '/user/delete'
  }
}

const tags = {
  createTag: {
    method: 'POST',
    baseEndPoint: '/app',
    endPoint: '/tags'
  },
  getAllTags: {
    method: 'GET',
    baseEndPoint: '/app',
    endPoint: '/tags/all'
  },
  patchTag: {
    method: 'PATCH',
    baseEndPoint: '/app',
    endPoint: '/tags'
  },
  deleteTag: {
    method: 'DELETE',
    baseEndPoint: '/app',
    endPoint: '/tags/delete'
  }
}

const glossary = {
  createGlossary: {
    method: 'POST',
    baseEndPoint: '/app',
    endPoint: '/glossary'
  },
  getAllGlossary: {
    method: 'GET',
    baseEndPoint: '/app',
    endPoint: '/glossary/all'
  },
  patchGlossary: {
    method: 'PATCH',
    baseEndPoint: '/app',
    endPoint: '/glossary'
  },
  deleteGlossary: {
    method: 'DELETE',
    baseEndPoint: '/app',
    endPoint: '/glossary/delete'
  }
}

const content = {
  createContent: {
    method: 'POST',
    baseEndPoint: '/app',
    endPoint: '/content'
  },
  getAllContent: {
    method: 'GET',
    baseEndPoint: '/app',
    endPoint: '/content/all'
  },
  patchContent: {
    method: 'PATCH',
    baseEndPoint: '/app',
    endPoint: '/content'
  },
  deleteContent: {
    method: 'DELETE',
    baseEndPoint: '/app',
    endPoint: '/content/delete'
  }
}

const training = {
  createTraining: {
    method: 'POST',
    baseEndPoint: '/app',
    endPoint: '/training'
  },
  getAllTraining: {
    method: 'GET',
    baseEndPoint: '/app',
    endPoint: '/training/all'
  },
  patchTraining: {
    method: 'PATCH',
    baseEndPoint: '/app',
    endPoint: '/training'
  },
  deleteTraining: {
    method: 'DELETE',
    baseEndPoint: '/app',
    endPoint: '/training/delete'
  }
}

const api = { baseUrl, appVersions, users, tags, glossary, content, training }

export default api
