import React, { useState, useEffect, useRef } from "react";
import { Table, Space, Button, Modal, Input, Select, Tag, Upload, UploadProps, TableProps, InputRef, Spin } from 'antd';
import { DeleteOutlined, EditOutlined, FilePdfOutlined, SearchOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from "antd/es/table/interface";
import '../../index.css';
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { storage } from "../../Firebase/firebase";
import { get } from "../../Utils/helpers";
import InputMultiple from "../../components/inputMultiple";

const { TextArea } = Input;
const { Option } = Select;

const Training = () => {
    const [tagsList, setTagsList] = useState([] as any[])
    const [trainingList, setTrainingList] = useState([] as any[])
    const [updatedList, setUpdatedList] = useState([] as any[])
    const [modalOpen, setModalOpen] = useState(false);
    const [action, setAction] = useState("")

    const [contentName, setContentName] = useState("")
    const [contentType, setContentType] = useState("TRAINING")
    const [contentSummary, setContentSummary] = useState("")
    const [contentValue, setContentValue] = useState("")
    const [contentCredit, setCreditValue] = useState("")
    const [contentUrl, setUrlValue] = useState("")
    const [contentTags, setContentTags] = useState([])
    const [creditUrl, setCreditUrlValue] = useState([] as any[])
    const [selectedPDFFiles, setSelectedPDFFiles] = useState([] as any[])
    const [existingPDFFiles, setExistingPDFFiles] = useState([] as any[])
    const [pdfFileList, setpdfFileList] = useState([] as any[])
    const [contentid, setContentid] = useState("")

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState("ascend");
    const [sortColumn, setSortColumn] = useState("content_name");

    useEffect(() => {
        const initialFiles = existingPDFFiles.map((file, index) => ({
            uid: `-existing-${index}`,
            name: file.fileName,
            status: "done",
            url: file.path
        }));
        setpdfFileList(initialFiles);
    }, [existingPDFFiles]);

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: any,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput?.current?.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <span style={{ backgroundColor: '#ffc069' }}>{text}</span>
            ) : (
                text
            ),
    });

    const getColumnSearchPropsForTags = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex] ? record[dataIndex].some((item: string) => item.toLowerCase().includes(value.toLowerCase())) : [],
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput?.current?.select(), 100);
            }
        },
    });

    const pdfprops: UploadProps = {
        name: 'file',
        accept: "application/pdf",
        multiple: false,
        onChange: info => {
            console.log('info', info)
            if (info.file.status === 'removed') {
                setpdfFileList(pdfFileList.filter(file => file.uid !== info.file.uid));
            } else {
                const updatedList = pdfFileList.filter(file => file.uid !== info.file.uid);
                const newFileEntry = {
                    uid: info.file.uid,
                    name: info.file.name,
                    status: info.file.status,
                    originFileObj: info.file.originFileObj,
                    url: info.file.url || '',
                };
                setpdfFileList([...updatedList, newFileEntry]);
            }
        },
        fileList: pdfFileList,
        showUploadList: {
            showDownloadIcon: true,
            downloadIcon: <DownloadOutlined/>,
            showRemoveIcon: true,
            removeIcon: (file) => (
                <DeleteOutlined onClick={(e) => {
                    e.stopPropagation();
                    setpdfFileList(pdfFileList.filter(f => f.uid !== file.uid));
                } }/>
            )
        },
    };

    const getStoragePath = async (files: any) => {
        console.log("files : ",  files);
        try {
            let file_paths = [];
            for (const file of files) {
                const timeStamp: any = new Date().getTime()

                if (file.originFileObj) {
                    await storage.ref(`content/${timeStamp}_${file.name}`).put(file.originFileObj);
    
                    const path: string = await storage.ref(`content`).child(`${timeStamp}_${file.name}`).getDownloadURL();
                    file_paths.push({fileName: file.name, path})
                } else {
                    file_paths.push({fileName: file.name, path: file.url})
                }
            }
            return file_paths;
        } catch (err) {
            console.log('err :>> ', err);
        }
    }

    const trainingTypeOptions = ['TRAINING']

    const columns: any = [
        {
            title: 'Training Name',
            dataIndex: 'content_name',
            key: 'content_name',
            sorter: true,
            ...getColumnSearchProps('content_name')
        },
        {
            title: 'Training Type',
            dataIndex: 'content_type',
            key: 'content_type',
            sorter: true,
            ...getColumnSearchProps('content_type')
        },
        {
            title: 'Training Summary',
            dataIndex: 'content_summary',
            key: 'content_summary',
            sorter: true,
            ...getColumnSearchProps('content_summary')
        },
        {
            title: 'Training Tags',
            dataIndex: 'content_tags',
            key: 'content_tags',
            render: (_: any, record: any) => (
                <>
                    {record.content_tags.map((tag: any) => {
                        return (
                            <Tag key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
            ...getColumnSearchPropsForTags('content_tags')
        },
        {
            title: 'Training Files',
            dataIndex: 'content_url',
            key: 'content_url',
            render: (url: any) => {
                if (url.pdfFilepaths.length > 0) {
                    return (
                        <>
                            {url.pdfFilepaths.map((link: any) => {
                                return (
                                    <a key={link.path} href={link.path} target="_blank" rel="noopener noreferrer">
                                        <FilePdfOutlined />
                                    </a>
                                );
                            })}
                        </>
                    );
                }
            }
        },
        {
            title: 'Training Credits',
            dataIndex: 'content_credits',
            key: 'content_credits',
            sorter: true,
            ...getColumnSearchProps('content_credits')
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle">
                    <Button className="action-button" icon={<EditOutlined />} onClick={(e) => {
                        setAction("edit")
                        setContentName(record.content_name)
                        setContentType(record.content_type)
                        setContentSummary(record.content_summary)
                        setContentValue(record.content_value)
                        setCreditValue(record.content_credits)
                        setUrlValue(record.content_url)
                        setContentTags(record.content_tags)
                        setContentid(record.content_id)
                        setExistingPDFFiles(record.content_url.pdfFilepaths || [])
                        setCreditUrlValue(record.credit_url)
                        setModalOpen(true)
                    }} />
                    <Button className="action-button" icon={<DeleteOutlined />} onClick={(e) => {
                        deleteTraining(record.content_id)
                    }} />
                </Space>
            )
        }
    ]

    useEffect(() => {
        fetchTrainingData();
        fetchTagsData();
    }, [])

    const fetchTrainingData = async () => {
        const data = {
            api: api.training.getAllTraining
        }
        setLoading(true);
        try {
            const response = await backend.fetch(data)
            console.log("response : ", response)
            if (response) {
                setTrainingList(response)
                if (!!sortOrder && !!sortColumn) {
                    let data = getSortedResult(response, sortColumn, sortOrder)
                    setUpdatedList(data)
                } else {
                    setUpdatedList(response)
                }
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const fetchTagsData = async () => {
        const data = {
            api: api.tags.getAllTags
        }
        try {
            const response = await backend.fetch(data)
            if (response) {
                setTagsList(response)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const addTraining = async () => {
        try {
            setModalLoading(true)
            let pdfFilepaths = await getStoragePath(pdfFileList);

            const data = {
                api: api.training.createTraining,
                payLoad: {
                    content_type: contentType,
                    content_name: contentName || "",
                    content_summary: contentSummary || "",
                    content_value: contentValue || "",
                    content_url: {
                        pdfFilepaths: pdfFilepaths
                    },
                    content_credits: contentCredit || "",
                    credit_url: creditUrl || [],
                    content_tags: contentTags || []
                }
            }
            await backend.save(data)
        } catch (err) {
            console.log(err)
        } finally {
            setModalLoading(false)
            setModalOpen(false)
        }

        clearValues();

        fetchTrainingData();
    }

    const editTraining = async () => {
        try {
            setModalLoading(true)
            let pdfFilepaths: any = await getStoragePath(pdfFileList);

            const data = {
                api: api.training.patchTraining,
                payLoad: {
                    content_id: contentid,
                    content_name: contentName || "",
                    content_summary: contentSummary || "",
                    content_value: contentValue || "",
                    content_credits: contentCredit || "",
                    content_url: {
                        pdfFilepaths: pdfFilepaths,
                    },
                    credit_url: creditUrl || [],
                    content_tags: contentTags || []
                }
            }
            await backend.save(data)
        } catch (err) {
            console.log(err)
        } finally {
            setModalLoading(false)
            setModalOpen(false)
        }

        clearValues();

        fetchTrainingData();
    }

    const deleteTraining = async (content_id: string) => {
        Modal.confirm({
            title: 'Are you sure to delete this training content?',
            content: 'This action cannot be undone',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const data = {
                    api: api.training.deleteTraining,
                    urlParam: content_id
                }
                try {
                    await backend.remove(data)
                } catch (err) {
                    console.log(err)
                }
                fetchTrainingData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const clearValues = () => {
        setContentName("")
        setContentSummary("")
        setContentValue("")
        setCreditValue("")
        setCreditUrlValue([])
        setUrlValue("")
        setContentTags([])
        setContentid("")
        setSelectedPDFFiles([])
        setExistingPDFFiles([])
        setpdfFileList([])
    }

    const getSortedResult = (data: any, columnKey: any, order: any) => {
        return data.sort((a: any, b: any) => {
            const valueA = get(a, columnKey, '');
            const valueB = get(b, columnKey, '');

            // Determine sorting direction
            const sortOrder = order === 'descend' ? -1 : 1;

            if (valueA < valueB) {
                return -1 * sortOrder;
            }
            if (valueA > valueB) {
                return 1 * sortOrder;
            }
            return 0;
        });
    }

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
        const sortParams: any = sorter;
        const { columnKey, order } = sortParams
        let data: any = [...trainingList]
        if (!!order) {
            data = getSortedResult(data, columnKey, order)
            console.log('data', data)
        }
        setUpdatedList(data)
        setSortOrder(order)
        setSortColumn(columnKey)
    };

    return (
        <div className="users-list-wrapper">
            {/* header */}
            <div className="users-list-header">
                <Button type="primary" onClick={() => {
                    console.log("-- contentType : --", contentType);
                    setAction("create")
                    setModalOpen(true)
                }}>
                    Create Training Content
                </Button>
                <Modal
                    title={action === "edit" ? "Edit Training Content" : "Create Training Content"}
                    centered
                    open={modalOpen}
                    onOk={() => {
                        if (action === "create") {
                            addTraining()
                        } else if (action === "edit") {
                            editTraining()
                        }
                    }}
                    onCancel={() => {
                        clearValues()
                        setModalOpen(false)
                    }}
                >
                    <Spin spinning={modalLoading}>
                        <div className="modal-content-add-edit-user">
                            {action === "create" && <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '150px' }}>Content Type</div>
                                <Select
                                    placeholder="Select Content Type"
                                    style={{ width: '100%', flexGrow: '1' }}
                                    onChange={(e) => {
                                        setContentType(e)
                                    }}
                                    allowClear
                                    value={contentType || trainingTypeOptions[0]}
                                >
                                    {trainingTypeOptions.map((item: any) => {
                                        return <Option value={item}>{item}</Option>
                                    })}
                                </Select>
                            </div>}

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '150px' }}>Content Name</div>
                                <Input placeholder="Training Content Name" value={contentName} onChange={(e) => setContentName(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '150px' }}>Content Credits</div>
                                <Input placeholder="Content Credits" value={contentCredit} onChange={(e) => setCreditValue(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '113px' }}>Credits URL</div>
                                <InputMultiple tags={creditUrl} setTags={setCreditUrlValue}/>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '150px' }}>Content Tags</div>
                                <Select
                                    mode="multiple"
                                    placeholder="Select Content Tags"
                                    style={{ width: '100%', flexGrow: '1' }}
                                    onChange={(e) => {
                                        setContentTags(e)
                                    }}
                                    allowClear
                                    value={contentTags}
                                >
                                    {tagsList.map((item: any) => {
                                        return <Option value={item.tag_name}>{item.tag_name}</Option>
                                    })}
                                </Select>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '150px' }}>Content Summary</div>
                                <TextArea rows={4} placeholder="Content Summary" value={contentSummary} onChange={(e) => setContentSummary(e.target.value)} />
                            </div>
                            {contentType === "TRAINING" && 
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ width: '113px' }}>PDF File: </div>
                                    <Upload {...pdfprops}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>}
                        </div>
                    </Spin>
                </Modal>
            </div>
            {/* content */}
            <div>
                <Table columns={columns} dataSource={updatedList} onChange={onChange} loading={loading} />
            </div>
        </div>
    )
}

export default Training;