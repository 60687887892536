import React, { useState, useEffect, useRef } from "react";
import { Table, Space, Button, Modal, Input, TableColumnsType, TableProps, InputRef, Spin } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import '../../index.css';
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { get } from "../../Utils/helpers";
import { FilterDropdownProps } from "antd/es/table/interface";

const UsersList = () => {

    const [usersList, setUsersList] = useState([] as any[])
    const [updatedList, setUpdatedList] = useState([] as any[])
    const [modalOpen, setModalOpen] = useState(false);
    const [action, setAction] = useState("")

    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [userpswd, setUserPswd] = useState("")
    const [userid, setUserid] = useState("")

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState("ascend");
    const [sortColumn, setSortColumn] = useState("user_name.user_name_first");

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: any,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
          record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible: any) => {
          if (visible) {
            setTimeout(() => searchInput?.current?.select(), 100);
          }
        },
        render: (text: any) =>
          searchedColumn === dataIndex ? (
            <span style={{ backgroundColor: '#ffc069' }}>{text}</span>
          ) : (
            text
          ),
      });


    const columns: TableColumnsType<any> = [
        {
            title: 'First Name',
            dataIndex: "user_name",
            key: 'user_name.user_name_first',
            render: (_: any, record: any) => (
                <span>
                    {record.user_name.user_name_first}
                </span>
            ),
            sorter: true            
        },
        {
            title: 'Last Name',
            dataIndex: "user_name",
            key: 'user_name.user_name_last',
            render: (_: any, record: any) => (
                <span>
                    {record.user_name.user_name_last}
                </span>
            ),
            sorter: true,
        },
        {
            title: 'Email',
            dataIndex: "user_email",
            key: 'user_email',
            sorter: true,
            ...getColumnSearchProps('user_email')
        },
        {
            title: 'Role',
            dataIndex: "role_code",
            key: 'role_code',
            sorter: true,
            ...getColumnSearchProps('role_code')
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle">
                    <Button className="action-button" icon={<EditOutlined />} onClick={(e) => {
                        setAction("edit")
                        setFirstname(record.user_name.user_name_first)
                        setLastname(record.user_name.user_name_last)
                        setUserid(record.guid)
                        setModalOpen(true)
                    }} />
                    <Button className="action-button" icon={<DeleteOutlined />} onClick={(e) => {
                        deleteUser(record.guid)
                    }} />
                </Space>
            ),
            className: 'action_column'
        },
    ]

    useEffect(() => {
        fetchUsersData()
    }, [])

    const fetchUsersData = async () => {
        const data = {
            api: api.users.getAllUsers
        }
        setLoading(true);
        try {
            const response = await backend.fetch(data)
            console.log("response : ", response)
            if (response) {
                setUsersList(response)
                if (!!sortOrder && !!sortColumn) {
                    let data = getSortedResult(response, sortColumn, sortOrder)
                    setUpdatedList(data)
                } else {
                    setUpdatedList(response)
                }
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const addUser = async () => {
        const data = {
            api: api.users.createUser,
            payLoad: {
                user_name_first: firstname,
                user_name_last: lastname,
                user_pswd: userpswd,
                user_email: email,
                role_code: "AGENT"
            }
        }
        try {
            setModalLoading(true)
            await backend.save(data)
        } catch (err) {
            console.log(err)
        } finally {
            setModalLoading(false)
            setModalOpen(false)
        }

        clearValues()

        fetchUsersData()
    }

    const editUser = async () => {
        const data = {
            api: api.users.patchUser,
            payLoad: {
                user_id: userid,
                user_name_first: firstname,
                user_name_last: lastname
            }
        }
        try {
            await backend.save(data)
        } catch (err) {
            console.log(err)
        }

        clearValues();

        fetchUsersData();
    }

    const deleteUser = async (guid: string) => {
        Modal.confirm({
            title: 'Are you sure to delete this user?',
            content: 'This action cannot be undone',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const data = {
                    api: api.users.deleteUser,
                    urlParam: guid
                }
                try {
                    await backend.remove(data)
                } catch (err) {
                    console.log(err)
                }

                fetchUsersData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const clearValues = () => {
        setFirstname("")
        setLastname("")
        setEmail("")
        setUserPswd("")
        setUserid("")
    }

    const getSortedResult = (data: any, columnKey: any, order: any) => {
        return data.sort((a: any, b: any) => {
            const valueA = get(a, columnKey, '');
            const valueB = get(b, columnKey, '');

            // Determine sorting direction
            const sortOrder = order === 'descend' ? -1 : 1;

            if (valueA < valueB) {
                return -1 * sortOrder;
            }
            if (valueA > valueB) {
                return 1 * sortOrder;
            }
            return 0;
        });
    }

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
        const sortParams: any = sorter;
        const { columnKey, order } = sortParams
        let data: any = [...usersList]
        if (!!order) {
            data = getSortedResult(data, columnKey, order)
            console.log('data', data)
        }
        setUpdatedList(data)
        setSortOrder(order)
        setSortColumn(columnKey)
    };

    return (
        <div className="users-list-wrapper">
            {/* header */}
            <div className="users-list-header">
                <Button type="primary" onClick={() => {
                    setAction("create")
                    setModalOpen(true)
                }}>
                    Create User
                </Button>
                <Modal
                    title={action === "edit" ? "Edit User" : "Create User"}
                    centered
                    open={modalOpen}
                    onOk={() => {
                        if (action === "create") {
                            addUser()
                        } else if (action === "edit") {
                            editUser()
                        }
                    }}
                    onCancel={() => {
                        clearValues()
                        setModalOpen(false)
                    }}
                >
                    <Spin spinning={modalLoading}>
                        <div className="modal-content-add-edit-user">
                            <div style={{ display: 'flex' }}>
                                <span style={{ width: '120px' }}>First Name</span>
                                <Input placeholder="First Name" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <span style={{ width: '120px' }}>Last Name</span>
                                <Input placeholder="Last Name" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                            </div>
                            {action === "create" && <div style={{ display: 'flex' }}>
                                <span style={{ width: '120px' }}>Email</span>
                                <Input placeholder="User Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>}
                            {action === "create" && <div style={{ display: 'flex' }}>
                                <span style={{ width: '120px' }}>Password</span>
                                <Input placeholder="User Password" value={userpswd} onChange={(e) => setUserPswd(e.target.value)} />
                            </div>}
                        </div>
                    </Spin>
                </Modal>
            </div>
            {/* content */}
            <div>
                <Table columns={columns} dataSource={updatedList} onChange={onChange} loading={loading}/>
            </div>
        </div>
    )
}

export default UsersList;