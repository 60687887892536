import React, { useState, useEffect } from "react";
import "./dialog.css";

const SecondDialog = ({ primaryTag, closeModal, onDone, setCustomTagsList, setSecondaryTags, customTagsList, secondaryTags }: any) => {
  const [suggestedSecondaryTags, setSuggestedSecondaryTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTags, setSelectedTags] = useState(secondaryTags as any[]);
  const [customTags, setCustomTags] = useState(customTagsList as any[]);
  const [newCustomTag, setNewCustomTag] = useState("");

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        setLoading(true);

        const formattedPrimaryTag = primaryTag.replace(/\s+/g, "+");

        const response = await fetch(
          `https://api.datamuse.com/words?ml=${formattedPrimaryTag}&max=20`
        );
        const data = await response.json();
        console.log('data', data)

        if (data && data.length > 0) {
          const suggestions = data.map((item: any) => item.word);
          console.log('suggestions', suggestions)
          setSuggestedSecondaryTags(suggestions);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSuggestions();
  }, [primaryTag]);

  const toggleTagSelection = (index: any) => {    
    const value = suggestedSecondaryTags[index]
    let updatedTags = [...selectedTags]

    if(updatedTags.includes(value)){
      const selectedTagIndex = updatedTags.findIndex((item: any) => item === value)
      updatedTags.splice(selectedTagIndex, 1)
    } else {
      updatedTags = [ ...updatedTags, value]
    }    
    setSelectedTags(updatedTags);
    setSecondaryTags(updatedTags);
  };

  const handleReset = () => {
    setSelectedTags([]);
    setSecondaryTags([]);
  };

  const handleAddCustomTag = () => {
    if (newCustomTag.trim() !== "") {
      setCustomTags([...customTags, newCustomTag.trim()]);
      setCustomTagsList([...customTags, newCustomTag.trim()]);
      setNewCustomTag("");
    }
  };

  const handleClearCustomTags = () => {
    setCustomTags([]);
    setCustomTagsList([])
  };

  return (
      <div className="">
        <p>
          <label htmlFor="suggestedTags" style={{ display: "inline-block" }}>
            Secondary Tags:
          </label>
          <button
            className="Reset-button"
            onClick={handleReset}
            style={{ float: "right" }}
          >
            Reset
          </button>
        </p>

        <div className="Suggested-tags-container">
          {loading && <div className="Spinner">Loading...</div>}
          {!loading && suggestedSecondaryTags.length === 0 && (
            <p>No matches found</p>
          )}

          {!loading && suggestedSecondaryTags.length > 0 && (
            <div className="Scrollable-container">
              {suggestedSecondaryTags.map((tag: any, index: number) => (
                <span
                  key={index}
                  className={`Suggested-tag ${
                    selectedTags.includes(tag) ? "Selected-tag" : ""
                  }`}
                  onClick={() => toggleTagSelection(index)}
                >
                  {tag}
                </span>
              ))}
            </div>
          )}
        </div>

        <p>
          <label htmlFor="customTags" style={{ display: "inline-block" }}>
            Custom Tags:
          </label>
          <button
            className="Clear-button"
            onClick={handleClearCustomTags}
            style={{ float: "right" }}
          >
            Clear
          </button>
        </p>

        <div className="Suggested-tags-container Custom-tags-container">
          <div className="Scrollable-container">
            {customTags.map((tag) => (
              <span key={tag} className="Suggested-tag Selected-tag">
                {tag}
              </span>
            ))}
          </div>
        </div>

        <div className="Add-custom-tag-container">
          <input
            type="text"
            id="newCustomTag"
            placeholder="Add custom tag"
            value={newCustomTag}
            onChange={(e) => setNewCustomTag(e.target.value)}
          />
          <button className="Add-button" onClick={handleAddCustomTag}>
            Add
          </button>
        </div>
      </div>
  );
};

export default SecondDialog;
