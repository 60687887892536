import React, { useState, useEffect, useRef } from "react";
import { Table, Space, Button, Modal, Input, TableColumnsType, TableProps, InputRef, Spin } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from "antd/es/table/interface";
import '../../index.css';
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import { get } from "../../Utils/helpers";

const GlossaryList = () => {
    
    const [glossaryList, setGlossaryList] = useState([] as any[])
    const [updatedList, setUpdatedList] = useState([] as any[])
    const [modalOpen, setModalOpen] = useState(false);
    const [action, setAction] = useState("")

    const [glossaryname, setGlossaryname] = useState("")
    const [glossaryvalue, setGlossaryvalue] = useState("")
    const [glossaryid, setGlossaryid] = useState("")

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState("ascend");
    const [sortColumn, setSortColumn] = useState("glossary_name");

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: any,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput?.current?.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <span style={{ backgroundColor: '#ffc069' }}>{text}</span>
            ) : (
                text
            ),
    });

    const columns: TableColumnsType<any> = [
        {
            title: 'Glossary Name',
            dataIndex: 'glossary_name',
            key: 'glossary_name',
            sorter: true,
            ...getColumnSearchProps('glossary_name')
        },
        {
            title: 'Glossary Value',
            dataIndex: 'glossary_value',
            key: 'glossary_value',
            sorter: true,
            ...getColumnSearchProps('glossary_value')
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: any) => (
                <Space size="middle">
                    <Button className="action-button" icon={<EditOutlined />} onClick={(e) => {
                        setAction("edit")
                        setGlossaryname(record.glossary_name)
                        setGlossaryvalue(record.glossary_value)
                        setGlossaryid(record.glossary_id)
                        setModalOpen(true)
                    }} />
                    <Button className="action-button" icon={<DeleteOutlined />} onClick={(e) => {
                        deleteGlossary(record.glossary_id)
                    }} />
                </Space>
            ),
            className: 'action_column'
        }
    ]

    useEffect(() => {
        fetchGlossaryData();
    }, [])

    const fetchGlossaryData = async () => {
        const data = {
            api: api.glossary.getAllGlossary
        }
        setLoading(true);
        try {
            const response = await backend.fetch(data)
            if (response) {
                setGlossaryList(response)
                if (!!sortOrder && !!sortColumn) {
                    let data = getSortedResult(response, sortColumn, sortOrder)
                    setUpdatedList(data)
                } else {
                    setUpdatedList(response)
                }
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const addGlossary = async () => {
        const data = {
            api: api.glossary.createGlossary,
            payLoad: {
                glossary_name: glossaryname,
                glossary_value: glossaryvalue
            }
        }
        try {
            setModalLoading(true)
            await backend.save(data)
        } catch (err) {
            console.log(err)
        } finally {
            setModalLoading(false)
            setModalOpen(false)
        }

        clearValues();

        fetchGlossaryData();
    }

    const editGlossary = async () => {
        const data = {
            api: api.glossary.patchGlossary,
            payLoad: {
                glossary_id: glossaryid,
                glossary_name: glossaryname,
                glossary_value: glossaryvalue
            }
        }
        try {
            await backend.save(data)
        } catch (err) {
            console.log(err)
        }

        clearValues();

        fetchGlossaryData();
    }

    const deleteGlossary = async (glossary_id: string) => {
        Modal.confirm({
            title: 'Are you sure to delete this tag?',
            content: 'This action cannot be undone',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                const data = {
                    api: api.glossary.deleteGlossary,
                    urlParam: glossary_id
                }
                try {
                    await backend.remove(data)
                } catch (err) {
                    console.log(err)
                }
                fetchGlossaryData();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const clearValues = () => {
        setGlossaryname("")
        setGlossaryvalue("")
        setGlossaryid("")
    }

    const getSortedResult = (data: any, columnKey: any, order: any) => {
        return data.sort((a: any, b: any) => {
            const valueA = get(a, columnKey, '');
            const valueB = get(b, columnKey, '');

            // Determine sorting direction
            const sortOrder = order === 'descend' ? -1 : 1;

            if (valueA < valueB) {
                return -1 * sortOrder;
            }
            if (valueA > valueB) {
                return 1 * sortOrder;
            }
            return 0;
        });
    }

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
        const sortParams: any = sorter;
        const { columnKey, order } = sortParams
        let data: any = [...glossaryList]
        if (!!order) {
            data = getSortedResult(data, columnKey, order)
            console.log('data', data)
        }
        setUpdatedList(data)
        setSortOrder(order)
        setSortColumn(columnKey)
    };

    return (
        <div className="users-list-wrapper">
            {/* header */}
            <div className="users-list-header">
                <Button type="primary" onClick={() => {
                    setAction("create")
                    setModalOpen(true)
                }}>
                    Create Glossary
                </Button>
                <Modal
                    title={action === "edit" ? "Edit Tag" : "Create Tag"}
                    centered
                    open={modalOpen}
                    onOk={() => {
                        if (action === "create") {
                            addGlossary()
                        } else if (action === "edit") {
                            editGlossary()
                        }
                    }}
                    onCancel={() => {
                        clearValues()
                        setModalOpen(false)
                    }}
                >
                    <Spin spinning={modalLoading}>
                        <div className="modal-content-add-edit-user">
                            <div style={{ display: 'flex' , alignItems: 'center'}}>
                                <span style={{ width: '120px' }}>Glossaryname</span>
                                <Input placeholder="Glossary Name" value={glossaryname} onChange={(e) => setGlossaryname(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex' , alignItems: 'center'}}>
                                <span style={{ width: '120px' }}>Glossaryvalue</span>
                                <Input placeholder="Glossary Value" value={glossaryvalue} onChange={(e) => setGlossaryvalue(e.target.value)} />
                            </div>
                        </div>
                    </Spin>
                </Modal>
            </div>
            {/* content */}
            <div>
                <Table columns={columns} dataSource={updatedList} onChange={onChange} loading={loading}/>
            </div>
        </div>
    )
}

export default GlossaryList