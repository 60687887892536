import React, { useState } from "react";
import { Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { auth } from "../../Firebase";
import { useNavigate } from "react-router-dom";
import { emailValidator, passwordValidator } from "../../Utils/validators";
import '../../index.css';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const validateEmail = (event: any) => {
        setUsername(event.target.value);
      };
    
      const validatePassword = (event: any) => {
        setPassword(event.target.value);
      };

      const handleLogin = async (event: React.MouseEvent<HTMLButtonElement>) => {
        try {
          const emailError = emailValidator(username);
          const passwordError = passwordValidator(password)
          if (emailError !== '' || passwordError !== '') {
            return;
          }
    
          const userCredentials: firebase.auth.UserCredential = await auth.doSignInWithEmailAndPassword(
            username,
            password
          );
    
          if (!userCredentials) {
            throw new Error("Error while login. Please check your credentials");
          }
    
          if (userCredentials && userCredentials.user) {
            console.log("login successful!!", userCredentials);
    
            const user: firebase.User = userCredentials.user;
            console.log("user email : ", user.email);

            sessionStorage.setItem("loggedIn", "true")
            navigate("/users");
          } else {
            throw new Error("Error while login");
          }
        } catch (err: any) {
          console.error(err);
        }
      };

    return (
        <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
        >
          <div className="login-logo"/>
            <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your Username!' }]}
                
            >
                <Input 
                    prefix={<UserOutlined className="site-form-item-icon" />} 
                    onChange={validateEmail}
                    placeholder="Username" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    onChange={validatePassword}
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" className="login-form-button" onClick={(event: any) => {handleLogin(event)}}>
                    Log in
                </Button>
            </Form.Item>
        </Form>
    )
};

export default Login;