import React, { useState, useEffect, useRef } from "react";
import { Table, Space, Button, Modal, Input, Tag, TableColumnsType, TableProps, InputRef, Spin } from "antd";
import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/es/table/interface";
import "../../index.css";
import api from "../../Service/Api";
import backend from "../../Service/Backend";
import SecondDialog from "./secondaryDialog";
import { get } from "../../Utils/helpers";

const TagsList = () => {
    const [tagsList, setTagsList] = useState([] as any[]);
    const [updatedList, setUpdatedList] = useState([] as any[])
    const [modalOpen, setModalOpen] = useState(false);
    const [action, setAction] = useState("");

    const [tagname, setTagname] = useState("");
    const [tagid, setTagid] = useState("");

    const [secondaryTags, setSecondaryTags] = useState([])
    const [customTagsList, setCustomTagsList] = useState([])

    const [currentStep, setCurrentStep] = useState(0);
    const totalSteps = 2;

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState("ascend");
    const [sortColumn, setSortColumn] = useState("tag_name");

    const handleSearch = (
        selectedKeys: string[],
        confirm: FilterDropdownProps['confirm'],
        dataIndex: any,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
          record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible: any) => {
          if (visible) {
            setTimeout(() => searchInput?.current?.select(), 100);
          }
        },
        render: (text: any) =>
          searchedColumn === dataIndex ? (
            <span style={{ backgroundColor: '#ffc069' }}>{text}</span>
          ) : (
            text
          ),
      });

    const columns: TableColumnsType<any> = [
        {
            title: "Tag Name",
            dataIndex: "tag_name",
            key: "tag_name",
            sorter: true,
            ...getColumnSearchProps('tag_name')
        },
        {
            title: "Secondary Tags",
            dataIndex: "secondary_tags",
            key: "secondary_tags",
            render: (_: any, record: any) => (
                <>
                    {get(record, 'secondary_tags', []).map((tag: any) => {
                        return (
                            <Tag key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: "Custom Tags",
            dataIndex: "custom_tags",
            key: "custom_tags",
            render: (_: any, record: any) => (
                <>
                    {get(record, 'custom_tags', []).map((tag: any) => {
                        return (
                            <Tag key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (_: any, record: any) => (
                <Space size="middle">
                    <Button
                        className="action-button"
                        icon={<EditOutlined />}
                        onClick={(e) => {
                            console.log('record', record)
                            setAction("edit");
                            setTagname(record.tag_name);
                            setTagid(record.tag_id);
                            setSecondaryTags(get(record, 'secondary_tags', []))
                            setCustomTagsList(get(record, 'custom_tags', []))
                            setModalOpen(true);
                        }}
                    />
                    <Button
                        className="action-button"
                        icon={<DeleteOutlined />}
                        onClick={(e) => {
                            deleteTag(record.tag_id);
                        }}
                    />
                </Space>
            ),
            className: "action_column",
        },
    ];

    useEffect(() => {
        fetchTagsData();
    }, []);

    const fetchTagsData = async () => {
        const data = {
            api: api.tags.getAllTags,
        };
        setLoading(true);
        try {
            const response = await backend.fetch(data);
            if (response) {
                setTagsList(response);
                if (!!sortOrder && !!sortColumn) {
                    let data = getSortedResult(response, sortColumn, sortOrder)
                    setUpdatedList(data)
                } else {
                    setUpdatedList(response)
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false)
        }
    };

    const addTag = async () => {
        const data = {
            api: api.tags.createTag,
            payLoad: {
                tag_name: tagname,
                secondary_tags: secondaryTags,
                custom_tags: customTagsList
            },
        };
        console.log('data', data)
        try {
            setModalLoading(true)
            await backend.save(data);
        } catch (err) {
            console.log(err);
        } finally {
            setModalLoading(false)
            setModalOpen(false)
        }
        clearValues();
        fetchTagsData();
    };

    const editTag = async () => {
        const data = {
            api: api.tags.patchTag,
            payLoad: {
                tag_id: tagid,
                tag_name: tagname,
                secondary_tags: secondaryTags,
                custom_tags: customTagsList
            },
        };
        try {
            await backend.save(data);
        } catch (err) {
            console.log(err);
        }

        clearValues();

        fetchTagsData();
    };

    const deleteTag = async (tag_id: string) => {
        Modal.confirm({
            title: "Are you sure to delete this tag?",
            content: "This action cannot be undone",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async () => {
                const data = {
                    api: api.tags.deleteTag,
                    urlParam: tag_id,
                };
                try {
                    await backend.remove(data);
                } catch (err) {
                    console.log(err);
                }
                fetchTagsData();
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const clearValues = () => {
        setTagname("");
        setTagid("");
        setSecondaryTags([])
        setCustomTagsList([])
        setCurrentStep(0);
    };

    const closeModal = () => {
        clearValues();
        setModalOpen(false);
    };

    const getSortedResult = (data: any, columnKey: any, order: any) => {
        return data.sort((a: any, b: any) => {
            const valueA = get(a, columnKey, '');
            const valueB = get(b, columnKey, '');

            // Determine sorting direction
            const sortOrder = order === 'descend' ? -1 : 1;

            if (valueA < valueB) {
                return -1 * sortOrder;
            }
            if (valueA > valueB) {
                return 1 * sortOrder;
            }
            return 0;
        });
    }

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
        const sortParams: any = sorter;
        const { columnKey, order } = sortParams
        let data: any = [...updatedList]
        if (!!order) {
            data = getSortedResult(data, columnKey, order)
            console.log('data', data)
        }
        setUpdatedList(data)
        setSortOrder(order)
        setSortColumn(columnKey)
    };

    return (
        <div className="users-list-wrapper">
            {/* header */}
            <div className="users-list-header">
                <Button
                    type="primary"
                    onClick={() => {
                        setAction("create");
                        setModalOpen(true);
                    }}
                >
                    Create Tag
                </Button>
                <Modal
                    title={action === "edit" ? `Edit Tag (${currentStep + 1}/${totalSteps})` : `Create Tag (${currentStep + 1}/${totalSteps})`}
                    centered
                    open={modalOpen}
                    okText={currentStep === 0 ? 'Next' : 'Ok'}
                    cancelText={currentStep === 0 ? 'Cancel' : 'Back'}
                    onOk={() => {
                        
                        if (action === "create") {
                            if (currentStep === 0) {
                                setCurrentStep(1);
                            } else {
                                addTag();
                            }
                        } else if (action === "edit") {
                            if (currentStep === 0) {
                                setCurrentStep(1);
                            } else {
                                editTag();
                            }
                        }
                    }}
                    onCancel={() => {
                        if (currentStep === 0) {
                            setCurrentStep(0);
                            clearValues();
                            setModalOpen(false);
                        } else {
                            setCurrentStep(currentStep - 1);
                        }
                    }}
                >
                    <Spin spinning={modalLoading}>
                        <div style={{ display: "flex", alignItems: "center" }}>

                            {currentStep === 0 && (
                                <>
                                    <span style={{ width: "120px" }}>Tagname</span>
                                    <Input
                                        placeholder="Tag Name"
                                        value={tagname}
                                        onChange={(e) => setTagname(e.target.value)}
                                    />
                                </>
                            )}
                            {currentStep === 1 && (
                                <SecondDialog
                                    primaryTag={tagname}
                                    closeModal={closeModal}
                                    onDone={addTag}
                                    secondaryTags={secondaryTags}
                                    setSecondaryTags={setSecondaryTags}
                                    customTagsList={customTagsList}
                                    setCustomTagsList={setCustomTagsList}
                                />
                            )}
                        </div>
                    </Spin>

                </Modal>
            </div>
            {/* content */}
            <div>
                <Table columns={columns} dataSource={updatedList} onChange={onChange} loading={loading} />
            </div>
        </div>
    );
};

export default TagsList;