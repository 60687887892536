import React, { useState } from "react";
import { Button, Input, Space, Tag, } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const InputMultiple = (props: any) => {
  const { tags, setTags } = props;

  const [singleValue, setSingleValue] = useState('')

  const handleChangeSingleValue = (e: any) => {
    setSingleValue(e.target.value)
  }

  const handleAdd = () => {
    setTags([...tags, singleValue])
    setSingleValue('')
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '10px', flexGrow: 1}}>
      <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
        {tags.map((tag: any, index: number) => {
          return (
            <Tag key={tag}>
              <>
                {tag}
                {' '}
                <Button type="text" style={{ margin: 0, border: 'none', padding: 0 }} onClick={() => {
                  const updatedTags = [...tags]
                  updatedTags.splice(index, 1)
                  setTags(updatedTags)
                }}><CloseOutlined /></Button>
              </>
            </Tag>
          );
        })}
      </div>
      <Space.Compact style={{ width: '100%' }}>
        <Input value={singleValue} onChange={handleChangeSingleValue} style={{ height: '32px' }} />
        <Button type="primary" disabled={!(!!singleValue)} onClick={handleAdd}>Add Link</Button>
      </Space.Compact>
    </div>
  )
};
export default InputMultiple;