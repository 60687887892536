import React from 'react';
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from './ProtectedRoute';
import Layout from './components/layout';
import Login from './pages/login';
import UsersList from './pages/users';
import TagsList from './pages/tags';
import GlossaryList from './pages/glossary';
import Training from './pages/training';
import './App.css';
import Content from './pages/content';

function App() {
  return (
    <div className="App">
      <Routes>        
          <Route path='/content' element={
            <ProtectedRoute>
              <Layout>
                <Content />
              </Layout>
            </ProtectedRoute>
          } />
          <Route path='/users' element={
            <ProtectedRoute>
              <Layout>
                <UsersList />
              </Layout>
            </ProtectedRoute>
          } />
          <Route path='/tags' element={
            <ProtectedRoute>
              <Layout>
                <TagsList />
              </Layout>
            </ProtectedRoute>
          } />
          <Route path='/glossary' element={
            <ProtectedRoute>
              <Layout>
                <GlossaryList />
              </Layout>
            </ProtectedRoute>
          } />
          <Route path='/training' element={
            <ProtectedRoute>
              <Layout>
                <Training />
              </Layout>
            </ProtectedRoute>
          } />
          <Route path="/" element={<Login />}>
          {/* <Route path="*" element={<NoMatch />} /> */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
