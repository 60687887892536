// Form validations 

/**
 * Return Email validation message. 
 * @param {string} email - email string param.
 * @returns {string} This is the result
 */
export const emailValidator = (email: string): string => {
  if (!email) {
    return "Please enter email";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)){
    return "Invalid email format";
  }
  return "";
};

/**
 * Return Password validation message. 
 * @param {string} password - email string param.
 * @returns {string} This is the result
 */
export const passwordValidator = (password:string): string => {
  if (!password) {
    return "Please enter Password";
  } else if (password.length < 8) {
    return "Password must have a minimum 8 characters";
  }
  return "";
};
