import React from "react";
import { Layout, Button } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
import "./index.css"
import { auth } from "../../Firebase";
import { useNavigate, useLocation } from "react-router-dom";

const { Header, Content } = Layout;

const LayoutComp = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    const logout = async () => {
        sessionStorage.removeItem("loggedIn")
        await auth.doSignOut();
        navigate("/")
        //logout functionality
    }

    return (
        <Layout>
            <Header className="header">
                <div className="menu-buttons">
                <div className="header-logo"/>
                <Button type="text" className={pathname === "/users" ? "menu-button active" : "menu-button"} onClick={() => {
                    navigate("/users")
                }}>Users</Button>
                <Button type="text" className={pathname === "/content" ? "menu-button active" : "menu-button"} onClick={() => {
                    navigate("/content")
                }}>Content</Button>
                <Button type="text" className={pathname === "/training" ? "menu-button active" : "menu-button"} onClick={() => {
                    navigate("/training")
                }}>Training</Button>
                <Button type="text" className={pathname === "/tags" ? "menu-button active" : "menu-button"} onClick={() => {
                    navigate("/tags")
                }}>Tags</Button>
                <Button type="text" className={pathname === "/glossary" ? "menu-button active" : "menu-button"} onClick={() => {
                    navigate("/glossary")
                }}>Glossary</Button>
                </div>
                <div className="logout-button">
                    <Button
                        // type="primary"
                        icon={<PoweroffOutlined />}
                        onClick={() => logout()}
                    />
                </div>
            </Header>
            <Content>
                {props.children}
            </Content>
            {/* <Footer>Footer</Footer> */}
        </Layout>
    )
}

export default LayoutComp;